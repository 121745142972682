import { AnimatePresence, motion } from 'framer-motion';
import { LanguageCodeType } from 'noddi-provider';
import { Fragment, useRef, useState } from 'react';

import { cn } from '../helpers/utils';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import { SupportedLanguages } from '../types/lingui';

const ArrowTop = (
  <svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.5 0.5L10.2631 8.75L0.73686 8.75L5.5 0.5Z' fill='#1F1F1F' />
  </svg>
);

const ArrowDown = (
  <svg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.5 8.5L0.736861 0.249999L10.2631 0.25L5.5 8.5Z' fill='#1F1F1F' />
  </svg>
);

type NoddiLanguageSelectorProps = {
  supportedLanguages: SupportedLanguages;
  currentLanguage: LanguageCodeType;
  setCurrentLanguage: (locale: LanguageCodeType) => void;
  dropDownOnRightSide?: boolean;
};

export const NoddiLanguageSelector = ({
  supportedLanguages,
  currentLanguage,
  setCurrentLanguage,
  dropDownOnRightSide
}: NoddiLanguageSelectorProps) => {
  const options = Object.entries(supportedLanguages).map(([locale, details]) => ({
    value: locale as LanguageCodeType,
    label: details.label,
    flag: details.flag
  }));

  const currentLanguageDetails = supportedLanguages[currentLanguage];

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className='w-fit'>
      <button
        className='flex w-fit cursor-pointer items-center justify-between rounded-full bg-primary-white px-4 py-2'
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className='flex items-center'>
          <span>{currentLanguageDetails?.flag}</span>
          <span className='ml-4 hidden sm:block'>{currentLanguageDetails?.label}</span>
        </div>
        <div className='ml-2'>{isOpen ? ArrowTop : ArrowDown}</div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
            className={cn(
              'absolute z-[10000] mt-2 w-50 rounded-2xl bg-primary-white shadow-md',
              dropDownOnRightSide && 'right-0'
            )}
          >
            {options.map((option) => (
              <Fragment key={option.value}>
                <button
                  className={cn(
                    'flex w-full cursor-pointer gap-4 p-2 [&:not(:last-child)]:border-b-[1.5px] [&:not(:last-child)]:border-systemColors-outlineStroke',
                    option.value === currentLanguage && 'font-bold'
                  )}
                  onClick={() => {
                    setCurrentLanguage(option.value);
                    setIsOpen(false);
                  }}
                >
                  <span className='w-10'>{option.flag}</span>
                  <span className='w-full text-start'>{option.label}</span>

                  <div>
                    <div className='ml-auto flex size-5 items-center justify-center rounded-full border border-systemColors-outlineStroke'>
                      {option.value === currentLanguage && (
                        <div>
                          <div className='size-4 rounded-full bg-primary-purple' />
                        </div>
                      )}
                    </div>
                  </div>
                </button>
              </Fragment>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

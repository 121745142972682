import { RouteItemForServiceWorker } from 'noddi-async/src/types';
import { NoddiButton, NoddiChip, NoddiFeedbackBox, NoddiIcon } from 'noddi-ui';
import { DateFormats, format, secondsToMinutes } from 'noddi-util';

interface RouteDetailItemProps {
  routeItem: RouteItemForServiceWorker;
  index: number;
  onNavigateToRouteItem?: (routeItemId: number) => void;
}

const RouteDetailItem = ({ routeItem, index, onNavigateToRouteItem }: RouteDetailItemProps) => {
  const {
    booking: { cancelledAt, completedAt, bookingItems },
    address
  } = routeItem;

  const expectedRoutePlanningArrival = `${format(routeItem.estimatedServiceStart, DateFormats.TIME)} - ${format(routeItem.estimatedDeparture, DateFormats.TIME)}`;
  const routeIsCancelledOrCompleted = completedAt || cancelledAt;

  const hasWaitingTime = routeItem.estimatedWaitingTimeInSeconds > 0;

  const expectedCustomerArrival = `The customer expects you to arrive between ${format(routeItem.booking.communicatedArrivalStart, DateFormats.TIME)} and ${format(routeItem.booking.communicatedArrivalEnd, DateFormats.TIME)}`;

  return (
    <>
      <div className='flex items-center justify-between'>
        <p className='font-bold'>Customer {index + 1}</p>
        {onNavigateToRouteItem && (
          <NoddiButton
            onClick={() => onNavigateToRouteItem(routeItem.id)}
            variant='link'
            startIcon='ArrowSquareRightCorner'
          >
            Go to job
          </NoddiButton>
        )}
      </div>
      <div className='mt-1 flex items-center gap-2'>
        <div>
          <NoddiIcon name='LocationPin' />
        </div>
        <p>
          {address.streetName} {address.streetNumber}
        </p>
      </div>
      <div className='mt-1 flex items-center gap-2'>
        <div>
          <NoddiIcon name='ClockCircle' />
        </div>
        <p>{expectedRoutePlanningArrival}</p>
      </div>

      {routeIsCancelledOrCompleted ? (
        <div className='mt-2'>
          <NoddiChip
            label={completedAt ? 'Completed' : 'Cancelled'}
            variant={completedAt ? 'success' : 'destructive'}
            icon={completedAt ? 'Check' : 'Cross'}
          />
        </div>
      ) : (
        <>
          <div className='mt-1 flex flex-col gap-2'>
            {bookingItems.map(({ car, orderLineDescriptions }) => (
              <div key={car.licensePlateNumber}>
                <div className='flex gap-2'>
                  <div>
                    <NoddiIcon name='Car' />
                  </div>
                  <p className='font-semibold'>
                    {car.make} {car.model} - {car.licensePlateNumber}
                  </p>
                </div>
                {orderLineDescriptions.map((description) => (
                  <div key={description} className='ml-8 flex flex-col'>
                    <p className='text-3'>{description}</p>
                  </div>
                ))}
              </div>
            ))}

            <p>{expectedCustomerArrival}</p>
          </div>

          <div className='mt-2'>
            {hasWaitingTime && (
              <NoddiFeedbackBox
                description={`${secondsToMinutes(routeItem.estimatedWaitingTimeInSeconds)} minutes waiting time`}
                variant='warning'
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default RouteDetailItem;
